// import icons
import { FaYoutube, FaInstagram, FaGithub } from 'react-icons/fa';
import { BsChatDotsFill } from 'react-icons/bs';

// import images
import AboutImg from '../src/assets/img/about.png';
import Feature1Img from '../src/assets/img/features/feature1.png';
import Feature2Img from '../src/assets/img/features/feature2.png';
import Feature3Img from '../src/assets/img/features/feature3.png';
import Feature4Img from '../src/assets/img/features/feature4.png';
import Avatar1Img from '../src/assets/img/testimonials/avatar1.png';
import Avatar2Img from '../src/assets/img/testimonials/avatar2.png';
import Avatar3Img from '../src/assets/img/testimonials/avatar3.png';
import LogoV2 from '../src/assets/img/Logo-v1.png';
import HeroImage from '../src/assets/img/hero-img.png';
import Feature1BgImg from '../src/assets/img/features/feature1_bg.png';
import Feature2BgImg from '../src/assets/img/features/feature2_bg.png';
import Feature3BgImg from '../src/assets/img/features/feature3_bg.png';
import Feature4BgImg from '../src/assets/img/features/feature4_bg.png';

export const navigationData = [
  {
    name: 'Entrega',
    href: '#',
  },
  {
    name: 'Servicios',
    href: '#',
  },
  {
    name: 'Sobre Nosotros',
    href: '#',
  },
  {
    name: 'Iniciar Sesión',
    href: '#',
  },
];

export const heroData = {
  title: `Ordene Productos Más Rápido y Fácilmente`,
  subtitle:
    'Ordene sus comidas favoritas en cualquier momento y se las entregaremos justo donde esté.',
  btnText: 'Empezar',
  image: HeroImage,
};

export const aboutData = {
  image: AboutImg,
  title: 'Descubre Un Poco Más Sobre Nosotros',
  subtitle:
    'Somos una empresa dedicada a la distribución de productos mediante entrega a domicilio o al lugar donde te encuentres, con la mejor calidad de servicio de entrega.',
};

export const featuresData = {
  title: 'Algunos Servicios Que Ofrecemos',
  subtitle:
    'Con nuestra aplicación puedes ver la ruta de tu pedido, desde nuestra sede local hasta el lugar donde te encuentres. ¡Descarga la aplicación ahora!',
  list: [
    {
      image: Feature1Img,
      bgImage: Feature1BgImg,
      title: 'Pago Realizado',
      description:
        'Pague con una tarjeta Visa o PayPal y sin mucho alboroto',
      linkText: 'Saber más',
      delay: '400',
    },
    {
      image: Feature2Img,
      bgImage: Feature2BgImg,
      title: 'Encuentra Tu Producto',
      description:
        'Ofrecemos venta de productos a través de Internet..',
      linkText: 'Saber más',
      delay: '700',
    },
    {
      image: Feature3Img,
      bgImage: Feature3BgImg,
      title: 'Imprimir',
      description:
        'El servicio de impresión te brinda comodidad si algún día necesitas imprimir datos, solo edítalo todo y luego imprímelo.',
      linkText: 'Saber más',
      delay: '1000',
    },
    {
      image: Feature4Img,
      bgImage: Feature4BgImg,
      title: 'Producto Recibido',
      description:
        'En nuestra aplicación puedes ver el tiempo de demora de tu pedido...',
      linkText: 'Saber más',
      delay: '1300',
    },
  ],
};

export const testimonialsData = [
  {
    image: Avatar1Img,
    name: 'Serena',
    web: 'rena.com',
    message:
      'Suspendisse ultrices at diam lectus nullam. Nisl, sagittis viverra enim erat tortor ultricies massa turpis. Arcu pulvinar aenean nam laoreet nulla.',
    delay: '300',
  },
  {
    image: Avatar2Img,
    name: 'Natalia',
    web: 'nataliya.com',
    message:
      'Suspendisse ultrices at diam lectus nullam. Nisl, sagittis viverra enim erat tortor ultricies massa turpis. Arcu pulvinar aenean nam laoreet nulla.',
    delay: '600',
  },
  {
    image: Avatar3Img,
    name: 'Vebin',
    web: 'vebin.com',
    message:
      'Suspendisse ultrices at diam lectus nullam. Nisl, sagittis viverra enim erat tortor ultricies massa turpis. Arcu pulvinar aenean nam laoreet nulla.',
    delay: '900',
  },
];

export const ctaData = {
  title: 'Contáctanos Desde Aquí',
  subtitle: '¡Obtén una prueba gratuita limitada de 1 semana de nuestras funciones!',
  btnText1: 'Saber más',
  btnText2: 'Solicitar Demostración',
};

export const footerData = {
  logo: LogoV2,
  address: 'Productos Heredia, Heredia, Costa Rica, 00000',
  email: 'info@productosheredia.com',
  phone: '+506 0000-0000 (Principal)',
  list1: [
    {
      name: 'Perfil',
      href: '#',
    },
    {
      name: 'Características',
      href: '#',
    },
    {
      name: 'Carreras',
      href: '#',
    },
    {
      name: 'Noticias DW',
      href: '#',
    },
  ],
  list2: [
    {
      name: 'Soporte',
      href: '#',
    },
    {
      name: 'Registrarse',
      href: '#',
    },
    {
      name: 'Guía',
      href: '#',
    },
    {
      name: 'Informes',
      href: '#',
    },
    {
      name: 'Preguntas y Respuestas',
      href: '#',
    },
  ],


  socialList: [
    {
      icon: <FaYoutube />,
      href: '#',
    },
    {
      icon: <FaInstagram />,
      href: '#',
    },
    {
      icon: <FaGithub />,
      href: '#',
    },
  ],
};

export const copyrightData = {
  text: '© WeCode, 2024. Todos los derechos reservados.',
  icon: <BsChatDotsFill />,
};
